.select2-sm .select2-container--bootstrap .select2-selection--single {
      height: 28px !important;
      padding: 4px 24px 4px 10px !important;
}
.select2-container .select2-selection--single {
  height: 34px !important;
  padding: 6px 24px 4px 10px;
}
.form-inline {
    margin-top:10px;
    margin-bottom:10px;
}

.CodeMirror {
  border: 1px solid var(--pri-border);
  height: auto !important;
  line-height: 14px;
}
.CodeMirror-scroll {
  height: auto;
}
